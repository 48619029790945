<template>
  <div class="public-studioAdmin-body">
    <StudioAdminTabs :list="tabs" :newIndex="view" @change="tabsChange"></StudioAdminTabs>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox public-form-studioAdmin">
          <div>
            <el-input v-model="form.search_title" placeholder="请输入资源名称" class="input" style="margin-left: 0"></el-input>

            <el-input v-model="form.search_realname" placeholder="上传者姓名" class="input width110"></el-input>

            <el-select @change="changeresource_type" v-model="form.search_resource_type" clearable placeholder="资源类别"
              class="sid" style="width: 110px;margin-left: 10px">
              <el-option v-for="item in optiones4" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.search_sources_id" clearable placeholder="资源类型" class="sid"
              style="width: 160px;margin-left: 10px">
              <el-option v-for="item in typeList2" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.search_type" clearable placeholder="所属文件夹" class="sid"
              style="width: 150px; margin-left: 10px">
              <el-option v-for="item in optiones1" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>

            <el-select v-model="form.show_type" placeholder="展示状态" class="sid" style="width: 120px; margin-left: 10px"
              v-if="view == 0">
              <el-option v-for="item in optiones_display" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.search_status" placeholder="审核状态" class="sid" style="width: 120px; margin-left: 10px"
              v-if="view == 1">
              <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-button class="ml10" type="primary" @click="search()">查询</el-button>
            <el-button @click="reset()">重置</el-button>
          </div>
          <div class="mt10 df dfb" v-if="view == 0">
            <div>
              <el-button type="primary" @click="recommendBatch()" :disabled="ids.length == 0">批量推荐</el-button>
              <el-button class="btn-blue" @click="delBatch()" :disabled="ids.length == 0">批量删除</el-button>
              <el-button class="btn-blue" @click="move()" :disabled="ids.length == 0">移动到</el-button>
              <el-button class="btn" @click="add(1)">上传微课</el-button>
              <el-button class="btn" @click="add(2)">上传文档</el-button>
            </div>
            <div>
              <el-button class="btn-green" @click="folder()">资源文件夹管理</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :stripe="true" @selection-change="tableSelectionChange"
          class="public-table-studioAdmin">
          <el-table-column type="selection" width="45">
          </el-table-column>
          <el-table-column label="资源信息" head-align="center">
            <template slot-scope="scope">
              <div class="studioAdmin-info">
                <div class="info-cover">
                  <img :src="scope.row.mime | showImg">
                  <span class="cover-labelTop" v-if="scope.row.is_recommend == 1">{{ scope.row.is_recommend == 1 ? "推荐" : "" }}</span>
                </div>
                <div class="info-box">
                  <p class="info-title">{{ scope.row.title }}</p>
                  <div class="info-block">
                    <span class="block-item block-item-label" v-if="scope.row.resource_type_str">{{ scope.row.resource_type_str }}</span>
                    <span class="block-item block-item-label" v-if="scope.row.sources_name">{{ scope.row.sources_name }}</span>
                    <span class="block-item">{{ scope.row.size }}</span>
                    <span class="block-item">{{ scope.row.type_name }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上传者/上传时间" align="center" width="200">
            <template slot-scope="scope">
              {{ scope.row.create_time }}
              <br>
              {{ scope.row.username }}
            </template>
          </el-table-column>
          <el-table-column label="浏览" prop="view_count" align="center" width="70" v-if="view == 0"></el-table-column>
          <el-table-column label="展示状态" align="center" width="100" v-if="view == 0">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show" active-color="#3489FF" :active-value="1" :inactive-value="0"
                @change="isShowChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" align="center" width="100" v-if="view == 1">
            <template slot-scope="scope">
              <p class="text-color-orange" v-if="scope.row.audit_status == 1 || scope.row.audit_status == 2 || scope.row.audit_status == 3">待审核</p>
              <p class="text-color-red" v-if="scope.row.audit_status == 5">未通过</p>
              <p class="text-color-grey" v-if="scope.row.audit_status == 4">已通过</p>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" align="center" width="230">
            <template slot-scope="scope">
              <el-button type="text" class="text-color-blue" @click="detail(scope.row)">详情</el-button>
              <template v-if="view == 0">
                <el-button type="text" class="text-color-blue" @click="edit(scope.row)">编辑</el-button>
                <el-button type="text" @click="del([scope.row.id])" class="text-color-red">删除</el-button>
                <el-button type="text" class="text-color-blue" @click="top(scope.row)">{{ scope.row.is_recommend == 2 ? "推荐" : "取消推荐" }}</el-button>
              </template>
              <template v-if="view == 1 && (scope.row.audit_status == 1 || scope.row.audit_status == 2 || scope.row.audit_status == 3)">
                <el-button type="text" @click="examineButton(scope.row, 4)" class="text-color-blue">通过</el-button>
                <el-button type="text" @click="examineButton(scope.row, 5)" class="text-color-red">不通过</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>


    <!-- 移动文件弹窗 -->
    <el-dialog title="移动到" :visible.sync="moveOpen" width="500px" @close="moveDialogOnClose">
      <div class="move_dialog">
        <p>全部文件</p>
        <ul ref="uuRef">
          <li v-for="(e, i) in fileData" :key="i" class="fc cursor" :class="{ active: fileIndex == i }"
            @click="onSelectFloder(i, e.id)">
            <img src="@/assets/images/teacherStudio/floder.png" alt="" />
            <div v-if="e.is_new" class="fc ipt">
              <el-input v-model="createData.name" placeholder="请点击输入名称" clearable size="small"></el-input>
              <i class="el-icon-check cursor" @click="onConfirm"></i>
              <i class="el-icon-close cursor" @click="onCancel()"></i>
            </div>
            <span v-else class="title">{{ e.name }}</span>
          </li>
        </ul>
        <div class="fsb">
          <div class="new cursor" @click="onNewCreate">
            <span class="add">+</span>
            <span>新建文件夹</span>
          </div>
          <div class="btn cancel cursor" @click="moveOpen = false">取消</div>
          <div class="btn tj cursor" @click="onAdd">添加</div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { onShowImages } from "@/utils/utils";
export default {
  data() {
    return {
      optiones_display: [
        {
          id: 1,
          name: "展示",
        },
        {
          id: 2,
          name: "隐藏",
        },
        {
          id: 3,
          name: "推荐",
        },
      ],
      optiones: [
        {
          id: 1,
          name: "待审核",
        },
        {
          id: 4,
          name: "已通过",
        },
        {
          id: 5,
          name: "未通过",
        },
      ],
      optiones4: [
        {
          id: 1,
          name: "微课",
        },
        {
          id: 2,
          name: "文档",
        },
      ],
      typeList2: [],
      optiones1: [],
      //表格数据
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_title: "",
        search_type: "",
        teaching_studio_id: localStorage.getItem("studioId"),
        search_resource_type: "",
        search_sources_id: "",
        search_status: "",
        search_realname: "",
        order_type: 1,
        show_type: ""
      },
      //导航
      tabs: [
        {
          label: "研修资源管理",
          count: 0
        },
        {
          label: "研修资源审核",
          count: 0
        }
      ],
      //页面索引
      view: 0,
      //审核表单数据
      examineForm: {
        id: 0,
        teaching_studio_id: localStorage.getItem("studioId"),
        audit_status: 0,
        refusal: ''
      },
      //表格选择id
      ids: [],
      //移动到弹窗
      moveOpen: false,
      //移动文件到文件夹数据
      fileFloder: {
        ids: [],
        type: 0,
      },
      //选中文件索引
      fileIndex: -1,
      createData: {
        name: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      //是否正在创建文件夹
      is_creating: false,
      fileData: [],
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //判断是否审核页
    if (this.$route.query.hasOwnProperty('type') && this.$route.query.type == 2) {
      this.view = 1;
      this.form.order_type = 2;
    }
    this.init();
  },
  computed: {},
  methods: {
    //初始化文件夹数据
    initFile() {
      this.$axios.get("TeachingStudioUserResourceType/list", {
        params: {
          page: "",
          limit: "",
          search_name: "",
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        let temArr = res.data.data.data;
        let obj = { id: 1, name: "默认文件夹" };
        temArr.unshift(obj);
        this.optiones1 = temArr;
      });
    },
    changeresource_type(val) {
      this.form.search_sources_id = ""
      this.typeList2 = []
      if (val) {
        let params = {
          resource_type: val
        }
        this.$axios.get("/resourceTypes", { params }).then((res) => {
          let arr = res.data.data
          this.typeList2 = arr;
        });
      } else {
        this.typeList2 = []
      }
    },
    //确认添加文件到文件夹
    onAdd() {
      if (!this.fileFloder.type) {
        return this.$message.warning("请选择移入的文件夹");
      }
      this.fileFloder.ids = this.ids;
      this.$axios
        .put("TeachingStudioResource/ids_type", this.fileFloder)
        .then(() => {
          this.$message.success("文件移入成功");
          this.moveOpen = false;
          this.getList();
        });
    },
    //新建文件夹
    onNewCreate() {
      if (this.is_creating) {
        return this.$message.warning("正在创建文件夹中...");
      }
      this.fileData.push({ name: "", is_new: true });
      this.is_creating = true;
    },
    //取消新建文件夹
    onCancel() {
      this.createData.name = "";
      this.is_creating = false;
      //删除最后一项
      this.fileData.pop();
    },
    //文件加列表
    onGetFloders() {
      this.$axios
        .get("TeachingStudioUserResourceType/list", {
          params: {
            page: 1,
            limit: 50000,
            search_name: "",
            teaching_studio_id: localStorage.getItem("studioId"),
          },
        })
        .then((res) => {
          this.fileData = res.data.data.data;
          this.fileData.push({ id: 1, name: "默认文件夹" })
          this.fileData.map((e) => {
            e.is_new = false;
          });
        });
    },
    //确认新建文件夹
    onConfirm() {
      if (!this.createData.name.trim()) {
        return this.$message.warning("请输入文件夹名");
      }
      this.$axios
        .post("TeachingStudioUserResourceType/add", this.createData)
        .then((res) => {
          this.$message.success("新建文件夹成功");
          this.onGetFloders();
          this.createData.name = "";
          this.is_creating = false;
          // 创建成功默认选中当前文件加进入移入文件
          this.fileFloder.type = res.data.data.id;
        });
    },
    //选中文件夹
    onSelectFloder(index, id) {
      this.fileIndex = index;
      this.fileFloder.type = id;
    },
    //关闭移动到弹窗
    moveDialogOnClose() {
      this.moveOpen = false;
      this.fileFloder = this.$options.data().fileFloder;
      this.fileIndex = -1;
    },
    //表格多选框选中数据
    tableSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
    },
    //批量推荐
    recommendBatch() {
      this.top(this.ids);
    },
    //批量删除
    delBatch() {
      this.del(this.ids);
    },
    //移动到
    move() {
      this.moveOpen = true;
      this.onGetFloders();
    },
    //资源文件夹管理
    folder() {
      this.$router.push("/ResourceManage");
    },
    //新增
    add(val) {
      this.$router.push({
        path: "/uploadResSet",
        query: {
          type: val
        }
      })
    },
    //详情
    detail(e) {
      let routeData = this.$router.resolve({
        path: "/resourcedetail",
        query: {
          id: e.id,
          resource_id: e.resource_id,
          is_show_head: 1,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //切换回调
    tabsChange(val) {
      this.view = val;
      this.form.page = 1;
      this.form.search_title = "";
      this.form.search_type = "";
      this.form.search_resource_type = "";
      this.form.search_sources_id = "";
      this.form.search_status = "";
      this.form.search_realname = "";
      this.form.order_type = val == 0 ? 1 : 2;
      this.form.show_type = ""
      this.tableData = []
      this.init();
    },
    //初始化
    init() {
      this.getList();
      this.examineCount();
      this.initFile();
    },
    //列表
    getList() {
      this.$axios
        .get("TeachingStudioResource/listTeachingStudioUserResource", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.tableData.map((e) => {
            e.size = (e.size / (1024 * 1024)).toFixed(1) + "M";
          });
        });
    },
    //删除
    del(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.delApi,
        id,
      });
    },
    //删除接口
    delApi(id) {
      let params = {
        delId: id
      };
      this.$axios.delete("TeachingStudio/deleteTeachingStudioUserResource", {
        params
      }).then(() => {
        this.$message.success("删除成功");
        this.getList()
      });
    },
    //搜索
    search() {
      this.form.page = 1;
      this.getList();
    },
    //重置
    reset() {
      this.form.page = 1;
      this.form.search_title = "";
      this.form.search_type = "";
      this.form.search_resource_type = "";
      this.form.search_sources_id = "";
      this.form.search_status = "";
      this.form.search_realname = "";
      this.form.show_type = ""
      this.getList();
    },
    //每页条数回调
    handleSizeChange(val) {
      this.form.limit = val;
      this.getList();
    },
    //当前页回调
    handleCurrentChange(val) {
      this.form.page = val;
      this.getList();
    },
    //编辑
    edit(e) {
      this.$router.push({
        path: "/uploadResSet",
        query: {
          id: e.id,
          type: e.resource_type
        }
      })
    },
    //是否显示回调
    isShowChange(e) {
      this.$axios
        .put("TeachingStudioUserResource/set_is_show", {
          id: e.id
        })
        .then(() => {
          this.getList();
        });
    },
    //推荐回调
    top(e) {
      let params = {
        teaching_studio_id: localStorage.getItem('studioId')
      }
      let api = "";
      if (Array.isArray(e)) {
        params.ids = e;
        api = "TeachingStudio/arrayChange_is_recommend";
      } else {
        params.id = e.id;
        api = "TeachingStudio/change_is_recommend";
      }
      this.$axios
        .post(api, params)
        .then(() => {
          this.getList();
        });
    },
    //审核按钮
    examineButton(e, val) {
      this.examineForm.id = e.id;
      this.examineForm.audit_status = val;
      if (val == 4) {
        this.myConfirm({
          content: `是否确定通过审核？`,
          fn: this.examine
        });
      }
      if (val == 5) {
        this.$prompt('', '审核不通过', {
          customClass: 'examine-prompt',
          closeOnClickModal: false,
          showClose: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPattern: /^.+$/m,
          inputPlaceholder: '请输入拒绝理由',
          inputErrorMessage: '请输入拒绝理由'
        }).then((e) => {
          if (e.action == "confirm") {
            this.examineForm.refusal = e.value;
            this.examine();
          }
        }).catch(() => {

        });
      }
    },
    //审核
    examine() {
      this.$axios
        .put("TeachingStudioResource/statusResource", this.examineForm)
        .then(() => {
          this.getList();
          this.examineCount();
          this.examineForm.refusal = "";
        });
    },
    //审核不通过（提交）
    examinePopoverSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.examine();
        }
      });
    },
    //审核统计
    examineCount() {
      this.$axios
        .get("index/TeachingStudioUserResource/audit_status_resource", { params: { id: this.form.teaching_studio_id } })
        .then((res) => {
          this.tabs[1].count = res.data.data.audit_status_resource;
        });
    },
  },
  watch: {
    fileData: {
      handler(newVal) {
        this.$nextTick(() => {
          // 滚动条自动滚动到底部
          let uuE = this.$refs.uuRef;
          uuE.scrollTop = uuE.scrollHeight;
        });
      },
      deep: true,
    },
  },
  filters: {
    showImg(ext) {
      return onShowImages(ext);
    },
  },
};
</script>
<style lang="less" scoped>
.info-cover {
  width: 68px !important;
}
.move_dialog {
  font-size: 16px;
  color: #333;

  >p {
    color: #999;
    padding-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
    height: 300px;
    overflow-y: auto;

    li {
      margin-bottom: 34px;

      &.active {
        .title {
          color: #3489ff;
        }
      }

      &:last-child {
        //   margin-bottom: 0;
      }

      >img {
        margin-right: 6px;
      }

      .ipt {
        i {
          color: #3489ff;
          font-size: 20px;
          margin-left: 18px;
        }
      }
    }
  }

  .new {
    border: 1px solid #3489ff;
    border-radius: 6px;
    padding: 3px 14px 0 12px;
    color: #3489ff;

    .add {
      margin-right: 10px;
      font-size: 24px;
    }
  }

  .btn {
    width: 100px;
    height: 42px;
    line-height: 42px;
    background: #f7f7f7;
    border: 1px solid #ececec;
    border-radius: 6px;
    font-weight: 600;
    text-align: center;

    &.tj {
      background-color: #ff8201;
      color: #fff;
    }

    &.cancel {
      margin-left: 66px;
    }
  }
}
</style>
